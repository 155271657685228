import axios from "axios";
import { store } from "../redux/store";
import { handleLogout } from "../redux/authentication";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers["Content-Type"] = "application/json";
axios.defaults.headers["X-API-KEY"] = process.env.REACT_APP_API_KEY;
axios.defaults.headers["Accept"] = "application/json";

axios.interceptors.request.use(
    function (config) {
        const access_token = store.getState().auth.userData.accessToken;
        // //console.log(access_token);
        if (!!access_token) {
            config.headers.Authorization = `Bearer ${access_token}`;
        }

        // Do something before request is sent
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        //console.log(response);
        return response;
    },
    (error) => {
        console.log(error);
        if (error?.response?.status === 401) {
            store.dispatch(handleLogout());
            //throw error;
        }
        // whatever you want to do with the error
        throw error;
    }
);

export default axios;
