// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "../configs/axios";
// ** UseJWT import to get config
// import useJwt from "@src/auth/jwt/useJwt";

const config = {};

const initialUser = () => {
    const item = window.localStorage.getItem("userData");
    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : {};
};

export const login = createAsyncThunk(
    "auth/login",
    async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post("auth/login", data);

            console.log(
                "SHOULD PRINT ENV --------------------------------------------------------"
            );
            console.log(process.env.REACT_APP_API_URL);
            console.log(process.env.REACT_APP_API_KEY);

            console.log(response);
            console.log("----------------------");
            console.log(response.data);
            console.log("----------------------");

            console.log(response.data.success);

            if (
                response.data.success &&
                response.data.data.user.roles[0].id === 1
            ) {
                return {
                    accessToken: response.data.data.accessToken,
                    ...response.data.data.user,
                    ability: [
                        {
                            action: "manage",
                            subject: "all",
                        },
                    ],
                };
            }
            throw Error("Invalid username or password");
        } catch (error) {
            //
            return rejectWithValue("Invalid username or password");
        }
    }
);

export const authSlice = createSlice({
    name: "authentication",
    initialState: {
        userData: initialUser(),
    },
    reducers: {
        handleLogin: (state, action) => {
            state.userData = action.payload;
            state[config.storageTokenKeyName] =
                action.payload[config.storageTokenKeyName];
            state[config.storageRefreshTokenKeyName] =
                action.payload[config.storageRefreshTokenKeyName];
            localStorage.setItem("userData", JSON.stringify(action.payload));
            window.dispatchEvent(new Event("userStorage"));
            localStorage.setItem(
                config.storageTokenKeyName,
                JSON.stringify(action.payload.accessToken)
            );
            localStorage.setItem(
                config.storageRefreshTokenKeyName,
                JSON.stringify(action.payload.refreshToken)
            );
        },
        handleLogout: (state) => {
            state.userData = {};
            localStorage.removeItem("userData");
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login.fulfilled, (state, action) => {
            state.userData = action.payload;
            localStorage.setItem("userData", JSON.stringify(action.payload));
            window.dispatchEvent(new Event("userStorage"));
        });
    },
});

export const { handleLogin, handleLogout } = authSlice.actions;

export default authSlice.reducer;

export const selectUser = (state) => state.auth;
