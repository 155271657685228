// ** Reducers Imports
import auth from "./authentication";
import layout from "./layout";

const rootReducer = {
	auth,
	layout,
};

export default rootReducer;
