import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";

// ** Redux Imports
import { Provider } from "react-redux";
import { store } from "./redux/store";

// ** Spinner (Splash Screen)
import FallbackSpinner from "./components/spinner/Fallback-spinner";

import reportWebVitals from "./reportWebVitals";
import { AbilityContext } from "./utility/context/Can";
import ability from "./configs/acl/ability";

import { ToastContainer } from "react-toastify";

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// ** React Toastify
import "./assets/styles/react/libs/toastify/toastify.scss";

import "./assets/styles/core.scss";
import "./assets/styles/style.scss";

// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

ReactDOM.render(
	<Provider store={store}>
		<Suspense fallback={<FallbackSpinner />}>
			<AbilityContext.Provider value={ability}>
				<LazyApp />
				<ToastContainer newestOnTop />
			</AbilityContext.Provider>
		</Suspense>
	</Provider>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
